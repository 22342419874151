import styled, { css } from 'styled-components';
import { color, spacer, breakpointUp, breakpointDown, fontSize } from 'theme';
import { BREAKPOINTS } from '../../../../constants/breakpoints';
import ContentfulCallToAction from '../ContentfulCallToAction/ContentfulCallToAction';
import ContentfulImageBlock from '../ContentfulImageBlock/ContentfulImageBlock';

export const StyledCFCallToAction = styled(ContentfulCallToAction)`
  .category-button {
    font-weight: bold;

    &:hover {
      background-color: ${color('secondary-300')};
      color: ${color('white')};
    }

    ${breakpointDown(
      BREAKPOINTS.MD,
      css`
        font-size: ${fontSize('88')};
      `
    )};
  }

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      max-width: 80%;
    `
  )};
`;

export interface StyledCFImageBlockProps {
  size?: 'small' | 'normal' | 'full-height';
}

export const StyledCFImageBlock = styled(ContentfulImageBlock)<StyledCFImageBlockProps>`
  height: 200px;
  min-height: ${({ size }) =>
    (size === 'normal' && '350px') || (size === 'full-height' && '332px') || (size === 'small' && '250px')};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css<StyledCFImageBlockProps>`
      min-height: ${({ size }) =>
        (size === 'normal' && '350px') || (size === 'full-height' && '250px') || (size === 'small' && '250px')};
      height: ${({ size }) =>
        (size === 'full-height' && '100%') || (size === 'normal' && '350px') || (size === 'small' && '250px')};
    `
  )};
`;

export const StyledCFCTAWrapper = styled.div`
  position: absolute;

  padding: 0px ${spacer(100)};
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: ${spacer(200)};
`;
