import CurveSlanted from 'components/CurveSlanted/CurveSlanted';
import Typography from 'components/Typography/Typography';
import { BREAKPOINTS } from 'constants/breakpoints';
import styled, { css } from 'styled-components';
import { breakpointUp, color, fontSize, spacer, variable, zIndex } from 'theme';
import ContentfulCallToAction from '../ContentfulCallToAction/ContentfulCallToAction';
import ContentfulImageBlock from '../ContentfulImageBlock/ContentfulImageBlock';
import { CONTENTFUL_TILE_CONTENT_SIMPLE_VARIANTS, TileContentSimpleVariant } from '../types';

interface StyledTileContentWrapperProps extends VariantProps {
  backgroundColor?: string;
  hasLink?: boolean;
  imagePosition?: 'before' | 'after';
}

interface VariantProps {
  variant?: TileContentSimpleVariant;
}

export const StyledTileContentWrapper = styled.div<StyledTileContentWrapperProps>`
  display: flex;
  flex-direction: ${({ imagePosition }) => imagePosition && imagePosition === 'before' && 'row-reverse'};
  min-height: 200px;
  max-height: 250px;
  width: 100%;
  max-height: 250px;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : color('whisper-100'))};

  ${({ variant }) => {
    switch (variant) {
      case CONTENTFUL_TILE_CONTENT_SIMPLE_VARIANTS.SIMPLE_VARIANT:
        return css`
          border-radius: ${variable('border-radius')};

          img {
            border-radius: ${variable('border-radius')};
          }
        `;
      case CONTENTFUL_TILE_CONTENT_SIMPLE_VARIANTS.WIDER_CONTENT_VARIANT:
        return css`
          border-radius: ${variable('border-radius-long-extra')};

          img {
            border-radius: ${variable('border-radius-long-extra')};
          }
        `;
      default:
        return null;
    }
  }}

  img {
    transition: ${variable('transition')};

    ${({ imagePosition }) =>
      imagePosition && imagePosition === 'before'
        ? css`
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          `
        : css`
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
          `};
  }

  &:hover {
    img {
      transform: ${({ hasLink }) => hasLink && `scale(1.025)`};
    }
  }
`;

export const StyledImageBlock = styled(ContentfulImageBlock)`
  min-height: 180px;
  width: 100%;
`;

export const StyledTileContent = styled.div<VariantProps>`
  display: flex;
  flex-direction: column;
  gap: ${spacer(75)};
  padding: ${spacer(150)} ${spacer(25)} ${spacer(150)} ${spacer(125)};
  position: relative;
  flex: 0 1 50%;
  z-index: ${zIndex('base-overwrite')};

  ${({ variant }) => {
    switch (variant) {
      case CONTENTFUL_TILE_CONTENT_SIMPLE_VARIANTS.WIDER_CONTENT_VARIANT:
        return css`
          flex-basis: 70%;
          justify-content: center;
        `;
    }
  }}
`;

interface StyledCallToActionProps {
  textColor?: string;
}

export const StyledCallToAction = styled(ContentfulCallToAction)<StyledCallToActionProps>`
  width: fit-content;

  && {
    color: ${({ textColor }) => (textColor ? textColor : color('primary-300'))};

    &:hover {
      color: ${({ textColor }) => (textColor ? textColor : color('primary-400'))};
    }
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex: 0 1 50%;
`;

export const StyledCurveSlanted = styled(CurveSlanted)`
  left: ${({ width }) => `${(width || 60) / -1.87}px`};
  top: 0;
  position: absolute;
  z-index: ${zIndex('base')};
`;

export const StyledContentTitle = styled(Typography)<VariantProps>`
  ${({ variant }) => {
    switch (variant) {
      case CONTENTFUL_TILE_CONTENT_SIMPLE_VARIANTS.WIDER_CONTENT_VARIANT:
        return css`
          font-size: ${fontSize(125)};
          margin-top: 0px;

          ${breakpointUp(
            BREAKPOINTS.MD,
            css`
              font-size: ${fontSize(200)};
            `
          )};
        `;
    }
  }}
`;
