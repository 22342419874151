import styled, { css } from 'styled-components';
import { breakpointUp, spacer, variable } from 'theme';
import { BREAKPOINTS } from 'constants/breakpoints';
import Grid from 'components/Grid/Grid';
import ContentfulTileCategory from '../ContentfulTileCategory/ContentfulTileCategory';

interface StyledGroupingTilesProps {
  count: number;
}

export const StyledGroupingTiles = styled(Grid)<StyledGroupingTilesProps>`
  gap: ${spacer(100)};
  grid-template-columns: 1fr;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css<StyledGroupingTilesProps>`
      grid-template-columns: ${({ count }) => count > 1 && `1fr 1fr`};
    `
  )}
`;

export const StyledContentfulTileCategory = styled(ContentfulTileCategory)`
  transition: ${variable('transition')};
  padding: ${spacer(25)} 0px;

  &:hover {
    transform: scale(1.01);
  }
`;
