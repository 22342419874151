import { ITileContent } from 'types/ContentfulTypes';
import { DEFAULT_SIZE_KEY } from 'types/Image';
import { BORDER_RADIUS_VALUES } from '../../../../constants/styling';
import Typography from '../../../Typography/Typography';
import ContentfulPageLink from '../ContentfulPageLink/ContentfulPageLink';
import { CALL_TO_ACTION_TYPES, CONTENTFUL_TILE_CONTENT_SIMPLE_VARIANTS, TileContentSimpleVariant } from '../types';
import {
  ImageWrapper,
  StyledCallToAction,
  StyledContentTitle,
  StyledCurveSlanted,
  StyledImageBlock,
  StyledTileContent,
  StyledTileContentWrapper,
} from './ContentfulTileContentSimple.styled';

interface ContentfulTileContentSimpleProps {
  content: ITileContent;
  curveSkew?: number;
  curveWidth?: number;
  showCallToActionLink?: boolean;
  tileContentSimpleVariant?: TileContentSimpleVariant;
}

const ContentfulTileContentSimple = ({
  content,
  curveSkew = -15,
  curveWidth = 60,
  showCallToActionLink,
  tileContentSimpleVariant = CONTENTFUL_TILE_CONTENT_SIMPLE_VARIANTS.SIMPLE_VARIANT,
}: ContentfulTileContentSimpleProps) => {
  if (!content?.fields) {
    return null;
  }

  const { backgroundColor, description, icon, image, imagePosition, link: cta, textColor, title } = content.fields;
  const label = cta?.fields?.label;
  const ctaLink = cta?.fields?.link;
  const hasLink = !!ctaLink;

  return (
    <ContentfulPageLink link={showCallToActionLink ? undefined : ctaLink}>
      <StyledTileContentWrapper
        hasLink={!!hasLink}
        imagePosition={imagePosition}
        backgroundColor={backgroundColor}
        className="contentful-tile-content-simple"
        variant={tileContentSimpleVariant}
      >
        {(icon || title || cta) && (
          <StyledTileContent variant={tileContentSimpleVariant}>
            {title && (
              <StyledContentTitle
                fontSize={125}
                fontWeight="bold"
                marginTop={75}
                color="black"
                variant={tileContentSimpleVariant}
              >
                {title}
              </StyledContentTitle>
            )}
            {description && <Typography color="black">{description}</Typography>}

            {showCallToActionLink && cta && (
              <StyledCallToAction content={cta} type={CALL_TO_ACTION_TYPES.LINK} textColor={textColor}>
                <Typography color="" fontWeight="bold" textDecoration="underline">
                  {label}
                </Typography>
              </StyledCallToAction>
            )}
          </StyledTileContent>
        )}

        {image && (
          <ImageWrapper>
            {!!curveSkew && !!curveWidth && (
              <StyledCurveSlanted backgroundColor="whisper-100" skew={curveSkew} width={curveWidth} />
            )}
            <StyledImageBlock
              content={image}
              roundBorders={[BORDER_RADIUS_VALUES.TOP_RIGHT, BORDER_RADIUS_VALUES.BOTTOM_RIGHT]}
              sizes={{ [DEFAULT_SIZE_KEY]: 200 }}
            />
          </ImageWrapper>
        )}
      </StyledTileContentWrapper>
    </ContentfulPageLink>
  );
};

export default ContentfulTileContentSimple;
