import { FlexContent, FlexDirection, FontType, GridFlow, Spacer } from 'constants/styling';
import styled, { css } from 'styled-components';
import { marginMapper, MarginProps, paddingMapper, PaddingProps, spacer } from 'theme';

export interface StyledGridProps extends MarginProps, PaddingProps {
  alignContent?: FlexContent;
  as?: FontType;
  columnAutoFit?: boolean;
  columnGap?: Spacer;
  columns?: string | number;
  flow?: FlexDirection;
  fullWidth?: boolean;
  gap?: Spacer;
  gridAutoFlow?: GridFlow;
  isInline?: boolean;
  justifyContent?: FlexContent;
  rowGap?: Spacer;
  rows?: string | number;
}

export default styled.div<StyledGridProps>`
  display: grid;

  ${marginMapper}
  ${paddingMapper}

  align-content: ${({ alignContent }) => alignContent};
  column-gap: ${({ columnGap }) => spacer(columnGap)};
  display: ${({ isInline }) => isInline && 'inline-grid'};
  gap: ${({ gap }) => spacer(gap)};
  grid-auto-flow: ${({ flow }) => flow};
  grid-template-columns: ${({ columnAutoFit, columns }) =>
    columnAutoFit ? css`repeat(auto-fit, minmax(0, 1fr))` : columns};
  grid-template-rows: ${({ rows }) => rows};
  justify-content: ${({ justifyContent }) => justifyContent};
  row-gap: ${({ rowGap }) => spacer(rowGap)};
  grid-auto-flow: ${({ gridAutoFlow }) => gridAutoFlow};
  width: ${({ fullWidth }) => fullWidth && '100%'};
`;
