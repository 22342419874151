import { ITileContent } from 'types/ContentfulTypes';
import { ImageSizes } from 'types/Image';
import Box from '../../../Box/Box';
import {
  StyledCFCallToAction,
  StyledCFCTAWrapper,
  StyledCFImageBlock,
  StyledCFImageBlockProps,
} from './ContentfulTileCategory.styled';

export interface ContentfulTileCategoryProps extends StyledCFImageBlockProps {
  className?: string;
  content: ITileContent;
  fullHeight?: boolean;
  sizes?: ImageSizes;
}

const ContentfulTileCategory = ({
  className,
  content,
  fullHeight,
  size = 'normal',
  sizes,
}: ContentfulTileCategoryProps) => {
  if (!content?.fields) {
    return null;
  }

  const { image, link } = content.fields;

  return (
    <Box className={className} position="relative" fullHeight={fullHeight}>
      {image && <StyledCFImageBlock content={image} sizes={sizes} size={fullHeight ? 'full-height' : size} />}
      <StyledCFCTAWrapper>
        <StyledCFCallToAction buttonProps={{ className: 'category-button', color: 'white' }} content={link} />
      </StyledCFCTAWrapper>
    </Box>
  );
};

export default ContentfulTileCategory;
