import { HTMLAttributes, ReactNode } from 'react';
import StyledGrid, { StyledGridProps } from './Grid.styled';

export interface GridProps extends StyledGridProps, HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
}

const Grid = ({
  alignContent,
  as = 'div',
  children,
  className,
  columnAutoFit,
  columnGap,
  columns,
  flow,
  fullWidth,
  gap,
  gridAutoFlow,
  isInline,
  justifyContent,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  rowGap,
  rows,
  ...props
}: GridProps) => (
  <StyledGrid
    alignContent={alignContent}
    as={as}
    className={className}
    columnAutoFit={columnAutoFit}
    columnGap={columnGap}
    columns={columns}
    flow={flow}
    gap={gap}
    gridAutoFlow={gridAutoFlow}
    isInline={isInline}
    justifyContent={justifyContent}
    margin={margin}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    marginRight={marginRight}
    marginTop={marginTop}
    padding={padding}
    paddingBottom={paddingBottom}
    paddingLeft={paddingLeft}
    paddingRight={paddingRight}
    paddingTop={paddingTop}
    rows={rows}
    rowGap={rowGap}
    fullWidth={fullWidth}
    {...props}
  >
    {children}
  </StyledGrid>
);

export default Grid;
