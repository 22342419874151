import { StyledCurve, StyledSlant, StyledSlantProps } from './CurveSlanted.styled';

interface CurveSlantedProps extends StyledSlantProps {
  className?: string;
  height?: number;
}

const CurveSlanted = ({
  backgroundColor = 'white',
  className,
  height = 60,
  skew = -10,
  width = 60,
}: CurveSlantedProps) => (
  <StyledSlant backgroundColor={backgroundColor} className={className} skew={skew} width={width}>
    <StyledCurve backgroundColor={backgroundColor} curvePosition="top-left" height={height} width={width} />
  </StyledSlant>
);

export default CurveSlanted;
