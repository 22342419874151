import styled from 'styled-components';
import { Color } from 'types/Color';
import { color } from '../../theme/functions';
import Curve from '../Curve/Curve';

export interface StyledSlantProps {
  backgroundColor: Color;
  skew: number;
  width: number;
}

export const StyledSlant = styled.div<StyledSlantProps>`
  height: 100%;
  width: ${({ width }) => `${width}px`};
  left: ${({ width }) => `${width / -2}px`};
  background-color: ${({ backgroundColor }) => color(backgroundColor)};
  transform: ${({ skew }) => `skewX(${skew}deg)`};
`;

export const StyledCurve = styled(Curve)`
  position: absolute;
  left: 99%; //-1% to ensure overlap
  top: 0;
`;
