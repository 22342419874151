import ContentfulGroupingHeading from '../ContentfulGroupingHeading/ContentfulGroupingHeading';
import ContentfulGroupingWrapper from '../ContentfulGroupingWrapper/ContentfulGroupingWrapper';
import ContentfulTileContentSimple from '../ContentfulTileContentSimple/ContentfulTileContentSimple';
import { StyledGroupingTiles } from './ContentfulGroupingLeaderboards.styled';
import { IGroupingLeaderboards } from 'types/ContentfulTypes';

interface ContentfulGroupingLeaderboardsProps {
  content: IGroupingLeaderboards;
}

const ContentfulGroupingLeaderboards = ({ content }: ContentfulGroupingLeaderboardsProps) => {
  if (!content?.fields) {
    return null;
  }

  const { items, title } = content.fields;
  const amountOfItems = items.length;

  return (
    <ContentfulGroupingWrapper>
      {title && <ContentfulGroupingHeading>{title}</ContentfulGroupingHeading>}
      <StyledGroupingTiles count={amountOfItems}>
        {items.map((item) => (
          <ContentfulTileContentSimple
            showCallToActionLink
            key={item.sys.id}
            content={item}
            curveSkew={items?.length === 1 ? -25 : undefined}
            curveWidth={items?.length === 1 ? 100 : undefined}
          />
        ))}
      </StyledGroupingTiles>
    </ContentfulGroupingWrapper>
  );
};

export default ContentfulGroupingLeaderboards;
